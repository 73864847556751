import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, TextField, Paper, Grid, Tooltip, IconButton, InputAdornment, useMediaQuery, useTheme, Checkbox, FormControlLabel } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import TimerIcon from '@mui/icons-material/Timer';
import ShareButton from './ShareButton';
import { highlightSubject } from '../utils/subjectUtils';
import './TVModePanel.css';
import { useTranslation } from 'react-i18next';
import config from '../config';

const TVModePanel = ({ subjects, initIndex = 0, hasNextPage, onNextPage }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const [secondsToWatch, setSecondsToWatch] = useState(config.videoWatchSeconds);
  const [remainingSeconds, setRemainingSeconds] = useState(config.videoWatchSeconds);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const playerRef = useRef(null);
  const youtubePlayerRef = useRef(null);
  const [autoJumpToNextPage, setAutoJumpToNextPage] = useState(() => {
    const storedValue = sessionStorage.getItem('autoJumpToNextPage');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;

  useEffect(() => {
    const loadYouTubeAPI = () => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    if (!window.YT) {
      loadYouTubeAPI();
    } else {
      initializePlayer();
    }

    window.onYouTubeIframeAPIReady = initializePlayer;

    return () => {
      if (youtubePlayerRef.current) {
        youtubePlayerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(initIndex);
    setRemainingSeconds(secondsToWatch);
    if (isPlayerReady && youtubePlayerRef.current && initIndex < subjects.length) {
      const { videoId, startTime } = extractVideoInfo(subjects[initIndex].youtubeLink);
      if (typeof youtubePlayerRef?.current?.loadVideoById === 'function') {
        youtubePlayerRef?.current?.loadVideoById({
          videoId: videoId,
          startSeconds: startTime,
        });
      }
    }
  }, [initIndex, subjects, isPlayerReady, secondsToWatch]);

  useEffect(() => {
    let timer;
    if (isPlayerReady && youtubePlayerRef.current && currentIndex < subjects.length) {
      timer = setInterval(() => {
        if (typeof youtubePlayerRef.current.getPlayerState === 'function') {
          if (youtubePlayerRef.current.getPlayerState() === window.YT.PlayerState.PLAYING) {
            setRemainingSeconds((prevSeconds) => {
              if (prevSeconds <= 1) {
                nextVideo();
                return secondsToWatch;
              }
              return prevSeconds - 1;
            });
          }
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isPlayerReady, secondsToWatch, currentIndex, subjects.length]);

  useEffect(() => {
    if (isPlayerReady && youtubePlayerRef.current && currentIndex < subjects.length) {
      const { videoId, startTime } = extractVideoInfo(subjects[currentIndex].youtubeLink);
      if (typeof youtubePlayerRef?.current?.loadVideoById === 'function') {
        youtubePlayerRef?.current?.loadVideoById({
          videoId: videoId,
          startSeconds: startTime,
        });
      }
    }
  }, [currentIndex, subjects, isPlayerReady]);

  const initializePlayer = () => {
    if (subjects.length > 0) {
      const { videoId, startTime } = extractVideoInfo(subjects[initIndex].youtubeLink);
      youtubePlayerRef.current = new window.YT.Player(playerRef.current, {
        height: '560',
        width: '640',
        videoId: videoId,
        playerVars: {
          start: startTime,
        },
        events: {
          onReady: (event) => {
            setIsPlayerReady(true);
            event.target.playVideo();
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              setIsPlaying(true);
            } else if (event.data === window.YT.PlayerState.PAUSED || event.data === window.YT.PlayerState.ENDED) {
              setIsPlaying(false);
            }
            if (event.data === window.YT.PlayerState.ENDED) {
              nextVideo();
            }
          },
        },
      });
    }
  };

  const extractVideoInfo = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    const videoId = (match && match[2].length === 11) ? match[2] : null;

    const timeRegExp = /[?&]t=(\d+)/;
    const timeMatch = url.match(timeRegExp);
    const startTime = timeMatch ? parseInt(timeMatch[1], 10) : 0;

    return { videoId, startTime };
  };

  useEffect(() => {
    sessionStorage.setItem('autoJumpToNextPage', JSON.stringify(autoJumpToNextPage));
  }, [autoJumpToNextPage]);

  const handleAutoJumpChange = (event) => {
    setAutoJumpToNextPage(event.target.checked);
  };

  // Modify the nextVideo function to use autoJumpToNextPage
  const nextVideo = () => {
    if (currentIndex < subjects.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setRemainingSeconds(secondsToWatch);
    } else {
      setIsPlaying(false);
      if (autoJumpToNextPage && hasNextPage) {
        handleNextPage();
      } else {
        setCurrentIndex(0); // Reset to the first video when reaching the end
      }
    }
  };

  const handleSecondsToWatchChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setSecondsToWatch(value);
      setRemainingSeconds(value);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlayerReady && youtubePlayerRef.current) {
      if (isPlaying) {
        youtubePlayerRef.current.pauseVideo();
      } else {
        youtubePlayerRef.current.playVideo();
      }
    }
  };

  const handleNextPage = () => {
    onNextPage();
    setCurrentIndex(0);
    setRemainingSeconds(secondsToWatch);
  };

  const renderSimilarPhrases = (aiField) => {
    if (!aiField) return null;
    return Object.entries(aiField).map(([language, phrases]) => (
      <span>
         <span className='note2'> #<u>{language}:</u></span> {phrases}
      </span>
    ));
  };

  const joinedLangsString = aiField => aiField && Object.entries(aiField).map(([language, phrases]) => phrases).join(', ');

  if (currentIndex >= subjects.length) {
    return <Typography variant="h6">{t('tvModeFinished')}</Typography>;
  }

  const currentSubject = subjects[currentIndex];

  const DesktopControlPanel = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Typography variant="caption">{t('secondsToWatch')}</Typography>
      <TextField
        type="number"
        value={secondsToWatch}
        onChange={handleSecondsToWatchChange}
        sx={{ width: '80px', mb: 2 }}
        inputProps={{ min: 1 }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TimerIcon sx={{ mr: 1, color: '#4caf50' }} />
        <Typography variant="body2">
          {remainingSeconds}s
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
        <Tooltip title={t('previousSubject')}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton onClick={() => {
              setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
              setRemainingSeconds(secondsToWatch);
            }} disabled={currentIndex === 0}>
              <SkipPreviousIcon />
            </IconButton>
            <Typography variant="caption">{t('previous')}</Typography>
          </Box>
        </Tooltip>
        <Tooltip title={isPlaying ? t('stop') : t('resume')}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 1 }}>
            <IconButton onClick={togglePlayPause}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Typography variant="caption">{isPlaying ? t('pause') : t('play')}</Typography>
          </Box>
        </Tooltip>
        <Tooltip title={t('nextSubject')}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton onClick={nextVideo} disabled={currentIndex === subjects.length - 1}>
              <SkipNextIcon />
            </IconButton>
            <Typography variant="caption">{t('next')}</Typography>
          </Box>
        </Tooltip>
      </Box>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {currentIndex + 1} / {subjects.length}
      </Typography>
      {hasNextPage && (
        <Button
          variant="contained"
          onClick={handleNextPage}
          color="secondary"
          sx={{ mt: 'auto' }}
        >
          {t('nextPage')}
        </Button>
      )}
    </Box>
  );

  const MobileControlPanel = () => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      width: '100%',
      gap: 2
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Typography variant="caption" sx={{ mr: 1 }}>{t('secondsToWatchOnMobile')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 0 : 2 }}>
          <IconButton
            onClick={() => handleSecondsToWatchChange({ target: { value: Math.max(1, secondsToWatch - 1) } })}
            size="small"
            sx={{ padding: '4px' }}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
          <TextField
            type="number"
            value={secondsToWatch}
            onChange={handleSecondsToWatchChange}
            sx={{ 
              width: '50px', 
              mx: 1,
              '& input': { 
                padding: '4px 0',
                textAlign: 'center',
              }
            }}
            inputProps={{ 
              min: 1,
              style: { fontSize: '14px' }
            }}
          />
          <IconButton
            onClick={() => handleSecondsToWatchChange({ target: { value: secondsToWatch + 1 } })}
            size="small"
            sx={{ padding: '4px' }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <TimerIcon sx={{ mr: 1, color: '#4caf50' }} />
        <Typography variant="body2">
          {remainingSeconds}s
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Tooltip title={t('previousSubject')}>
          <IconButton onClick={() => {
            setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
            setRemainingSeconds(secondsToWatch);
          }} disabled={currentIndex === 0}>
            <SkipPreviousIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={isPlaying ? t('stop') : t('resume')}>
          <IconButton onClick={togglePlayPause} sx={{ mx: 2 }}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('nextSubject')}>
          <IconButton onClick={nextVideo} disabled={currentIndex === subjects.length - 1}>
            <SkipNextIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography variant="body2" align="center">
        {currentIndex + 1} / {subjects.length}
      </Typography>

      {hasNextPage && (
        <Button
          variant="contained"
          onClick={handleNextPage}
          color="secondary"
          fullWidth
        >
          {t('nextPage')}
        </Button>
      )}
    </Box>
  );

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '1200px', mx: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={isMobile ? 12 : 10}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box ref={playerRef} sx={{ width: '100%', height: '360px' }} />
          </Paper>
          <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
            <Typography variant="h5" className="intro-heading" gutterBottom>
              {currentSubject[subjectName]}
              <ShareButton
                id={currentSubject.id}
                subject={currentSubject[subjectName]}
                similarPhrase={joinedLangsString(currentSubject[`${dataName.aiFields.similarPhrases}`])}
                snippet={currentSubject.context}
              />
            </Typography>
            
            {dataName.aiFields.category && (
              <Typography 
                variant="caption" 
                sx={{ 
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  backgroundColor: 'rgba(245, 245, 245, 0.9)',
                  padding: '4px 8px',
                  borderRadius: '4px',
                }}
              >
                <span className="note">Category: </span>
                {currentSubject[`${dataName.aiFields.category}`]?.join(' #')}
              </Typography>
            )}

            <Typography variant="body1" sx={{ mb: 2 }}>
              {highlightSubject(currentSubject.context, currentSubject[subjectName])}
              <span className="source-note"> [from the Youtube video]</span>
            </Typography>
            {dataName.aiFields.explanation && (
              <Typography variant="body2" sx={{ mb: 2 }}>
                <span className="note">Explanation: </span>
                {currentSubject[`${dataName.aiFields.explanation}`]}
              </Typography>
            )}
            {dataName.aiFields.significance && (
              <Typography variant="body2" sx={{ mb: 2 }}>
                <span className="note">AI comment: </span>
                {currentSubject[`${dataName.aiFields.significance}`]}
              </Typography>
            )}
            {dataName.aiFields.sentenceExample && (
              <Typography variant="body2" sx={{ mb: 2 }}>
                <span className="note">Other example: </span>
                {currentSubject[`${dataName.aiFields.sentenceExample}`]}
              </Typography>
            )}
            {localStorage.getItem('selectedLangs')?.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" gutterBottom>
                  <span className="note">Similar phrases in 2nd languages: </span>
                  {renderSimilarPhrases(currentSubject[`${dataName.aiFields.similarPhrases}`])}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
        {!isMobile && (
          <Grid item md={2}>
            <Paper elevation={3} sx={{ p: 2, height: '95%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <DesktopControlPanel />
            </Paper>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <Paper elevation={3} sx={{ p: 2, mb: 2, width: '100%' }}>
          <MobileControlPanel />
        </Paper>
      )}
    </Box>
  );
};

export default TVModePanel;