import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import config from '../config';

import LineIcon from '@mui/icons-material/Chat';

const ShareButton = ({ id, subject, customUrl = '', similarPhrase, snippet }) => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [shared, setShared] = useState(false);

  useEffect(() => {
    const sharedItems = JSON.parse(localStorage.getItem('sharedItems') || '{}');
    setShared(!!sharedItems[id]);
  }, [id]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getShareUrl = () => {
    if (customUrl) {
      return customUrl;
    }
    const domain = config.domain || `http://${window.location.host}`;
    const lang = i18n.language;
    if (id) {
      return `${domain}?id=${id}&lang=${lang}`;
    }
    return `${domain}?id=${id}&subject=${encodeURIComponent(subject.substring(0,20))}&lang=${lang}`;
  };

  const updateSharedState = () => {
    const sharedItems = JSON.parse(localStorage.getItem('sharedItems') || '{}');
    sharedItems[id] = true;
    localStorage.setItem('sharedItems', JSON.stringify(sharedItems));
    setShared(true);
  };

  const handleShare = (platform) => {
    const shareUrl = getShareUrl();
    let url;
    const subjectName = config.dataNames[0].subject;

    const text = `- ${subject} (${similarPhrase})\n${snippet}\n`;

    switch (platform) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${shareUrl}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(subject)}&summary=${encodeURIComponent(text)}`;
        break;
      case 'reddit':
        url = `https://reddit.com/submit?url=${shareUrl}&title=${encodeURIComponent(text)}`;
        break;
      case 'line':
        url = `https://social-plugins.line.me/lineit/share?url=${shareUrl}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, '_blank');
      updateSharedState();
    }
    handleClose();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(getShareUrl()).then(() => {
      setSnackbarOpen(true);
      handleClose();
    });
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ color: shared ? '#76FF03' : 'inherit' }}
      >
        <ShareIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleShare('twitter')}>
          <TwitterIcon /> {t('shareOnTwitter')}
        </MenuItem>
        <MenuItem onClick={() => handleShare('facebook')}>
          <FacebookIcon /> {t('shareOnFacebook')}
        </MenuItem>
        <MenuItem onClick={() => handleShare('linkedin')}>
          <LinkedInIcon /> {t('shareOnLinkedIn')}
        </MenuItem>
        <MenuItem onClick={() => handleShare('reddit')}>
          <RedditIcon /> {t('shareOnReddit')}
        </MenuItem>
        <MenuItem onClick={() => handleShare('line')}>
          <LineIcon /> {t('shareOnLine')}
        </MenuItem>
        <MenuItem onClick={handleCopyLink}>
          <ContentCopyIcon /> {t('copyLink')}
        </MenuItem>
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={t('linkCopied')}
      />
    </>
  );
};

export default ShareButton;